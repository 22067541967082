<template>

  <div class="requestSuperUser">
      <b-container>
            
    <b-card bg-variant="light" class="text-center">
        <h2>Request Super User</h2>
        <p>Requests are valid for 24h. Co-ordinate with your VP before making a request.</p>
        <b-form id="requestForm" @submit.prevent="sendRequest">
                <b-form-group horizontal label-class="text-sm-right" :label-cols="5" breakpoint="md" id="username" label="Username" label-for="username">
                    <b-form-input id="username" type="text" v-model="su.username" required placeholder="Enter full name"></b-form-input>
                </b-form-group>
                <b-form-group horizontal  label-class="text-sm-right" :label-cols="5" breakpoint="md" id="email" label="Email" label-for="email">
                    <b-form-input id="email" type="email" v-model="su.email" required placeholder="Enter email"></b-form-input>
                </b-form-group>
                <b-form-group horizontal label-class="text-sm-right" :label-cols="5" breakpoint="md" id="vpList" label="VP" label-for="vpName">
                    <b-form-select id="vpName" required v-model="su.vpEmail">
                        <option value="" disabled selected>Please select VP</option>
                        <option v-for="vp in vpList" v-bind:value="vp.email" :key="vp.name">{{vp.name}} ({{vp.employeeId}}, {{vp.email}})</option>
                    </b-form-select>
                </b-form-group>
                <b-form-group horizontal label-class="text-sm-right" :label-cols="5" breakpoint="md" id="userType" label="User type" label-for="userType">
                    <b-form-select id="userType" required v-model="su.userType">
                        <option value="" disabled selected>Please select user type</option>
                        <option value="3">Admin</option>
                        <option value="2">Report</option>
                    </b-form-select>
                </b-form-group>
                <b-form-group horizontal label-class="text-sm-right" :label-cols="5" breakpoint="md" label="Default Language">
                    <b-form-radio-group id="langCode" v-model="su.language" class="text-sm-left" style="padding-top: 5px;" name="language">
                        <b-form-radio value="EN">English</b-form-radio>
                        <b-form-radio value="FR">French</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
                <b-form-group label-class="text-sm-right" horizontal :label-cols="5" breakpoint="md" label="Tenant Access">
                    <b-form-radio-group  id="tenantAccessFormGroup" v-model="su.tenantAccess" class="text-sm-left" style="padding-top: 5px;" name="tenantAccess">
                        <b-form-radio value="Global">Global</b-form-radio>
                        <b-form-radio value="Individual">Individual</b-form-radio>
                        <b-form-radio value="Group">Partner Group</b-form-radio>
                    </b-form-radio-group>
                    <b-form-input id="individualTenantAccess" type="text" v-model="su.individual" placeholder='Enter tenant name' v-if="this.su.tenantAccess == 'Individual'"></b-form-input>
                    <div v-if="this.su.tenantAccess == 'Group'">
                        <PartnerGroups v-model="groups"/>
                    </div>
                </b-form-group>
                <b-alert :show="responses != ''"  variant="success">{{responses}}</b-alert>
                <b-alert :show="errors != ''"  variant="danger">{{errors}}</b-alert>
                <b-button type="submit" variant="primary">Submit</b-button>
            </b-form>
        </b-card>
      </b-container>
    </div>
</template>


<script>
import axios from 'axios'
import PartnerGroups from '@/components/PartnerGroups.vue';

export default {
  name: 'RequestSuperUser',
  components: {
      PartnerGroups
  },
  data ()  {
    return {
        responses: '',
        errors: '',
        vpList: [],
        groups: {
            accessGroup: ''
        },
        su: {
            username: '',
            email: '',
            vpEmail: '',
            language: 'EN',
            userType: '',
            tenantAccess: 'Global',
            individual: ''
        }
    }
  },
  created () {
    this.getVpList();
  },
  methods:{
      getVpList: function(){
          axios.get("/api/getVpList").then(response => {
                this.vpList = response.data;
          }).catch(e => {
              this.errors = e;
          })
      },
      sendRequest: function(event){
        var request = {
            'username': this.su.username,
            'email': this.su.email,
            'vpEmail': this.su.vpEmail,
            'language': this.su.language,
            'userType': this.su.userType,
            'tenantAccess': this.su.tenantAccess,
            'tenantName': this.su.individual,
            'tenantAccessGroup': this.groups.accessGroup
        };
       
        axios.post('/api/requestSuperUser', request)
            .then(response => {
                if (response.data.error != "" && response.data.error != null) {
                    this.errors = response.data.error;
                    this.responses = '';
                }
                if (response.data.success != "" && response.data.success != null) {
                    this.responses = response.data.success;
                    this.errors = '';
                }
            }).catch(e => {
                this.errors = e;
            });
        }
    }
}
</script>
