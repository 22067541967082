<template>
  <div id="app">
    <RequestSuperUser/>
  </div>
</template>

<script>
import RequestSuperUser from '@/components/RequestSuperUser.vue'

export default {
  name: 'requestSuperUser',
  components: {
    RequestSuperUser
  }
}
</script>
